<template>
	<v-container fluid>
		<v-form ref="formulario">
			<v-row>
				<v-col cols="12" class="d-flex flex-wrap pb-0" style="gap: 10px">
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn v-on="on" v-bind="attrs" color="primary" large @click.stop="crearColaborador">
								<v-icon color="white" left>mdi-content-save</v-icon>GUARDAR COLABORADOR
							</v-btn>
						</template>
						<span>Guardar el colaborador</span>
					</v-tooltip>

					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								v-on="on"
								v-bind="attrs"
								large
								text
								class="mx-1"
								:color="errors == null ? '' : errors ? 'error' : 'success'"
								@click="errors = !$refs.formulario.validate()"
							>
								<v-icon left v-if="errors === true">mdi-alert-circle-outline</v-icon>
								<v-icon left v-else-if="errors === false">mdi-check</v-icon>Consultar campos
							</v-btn>
						</template>
						<span v-if="!errors">Consultar campos faltantes o erroneos</span>
						<span v-else>Hay campos erróneos, revisa todos los campos</span>
					</v-tooltip>
				</v-col>
				<v-col cols="12">
					<div class="d-flex flex-wrap">
						<v-checkbox
							class="ml-1 mt-0"
							hide-details
							v-model="colaborador.facturable"
							label="Facturable"
						></v-checkbox>
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" md="6">
					<tarjeta-datos header titulo="Datos colaborador" icon="account">
						<aux-input title="Nombre Canal">
							<v-text-field
								filled
								dense
								hide-details="auto"
								v-model="colaborador.nombreEmpresa"
								placeholder="Nombre Canal"
								:rules="colaborador.parent ? [true] : [rules.req, rules.canalRepetido]"
								:disabled="colaborador.parent != null"
							/>
						</aux-input>
						<v-divider />
						<aux-input title="Nombre">
							<v-text-field
								filled
								dense
								hide-details="auto"
								v-model="colaborador.nombreAgente"
								placeholder="Nombre Colaborador"
								:rules="[rules.req, rules.agenteRepetido]"
							/>
						</aux-input>
						<v-divider />
						<aux-input title="Correo">
							<v-text-field
								filled
								dense
								hide-details="auto"
								v-model="colaborador.correo"
								placeholder="Correo Colaborador"
								:rules="[rules.req, rules.email]"
							/>
						</aux-input>
						<v-divider />
						<aux-input title="Teléfono">
							<v-text-field
								filled
								dense
								hide-details="auto"
								v-model="colaborador.telefono"
								placeholder="Teléfono Colaborador"
								:rules="[rules.phone]"
							/>
						</aux-input>
						<v-divider />
						<aux-input title="Entorno">
							<v-autocomplete
								filled
								dense
								hide-details="auto"
								placeholder="Entorno"
								clearable
								v-model="colaborador.entorno"
								:items="['Luz', 'Gas', 'Dual']"
								:rules="[rules.req]"
							>
								<template v-slot:selection="{ item, selected, attrs, on }">
									<status-chip small :value="item"></status-chip>
								</template>

								<template v-slot:item="{ item }">
									<status-chip small :value="item"></status-chip>
								</template>
							</v-autocomplete>
						</aux-input>
						<v-divider />
						<aux-input title="Modelo factura">
							<v-autocomplete
								filled
								dense
								hide-details="auto"
								placeholder="Modelo factura"
								clearable
								v-model="colaborador.IdModeloFactura"
								:items="modelosFacturas"
								:rules="[rules.req]"
								item-text="texto"
								item-value="IdModeloDeImpresion"
							>
							</v-autocomplete>
						</aux-input>
						<v-divider />
						<aux-input title="Empresa Portal">
							<v-autocomplete
								filled
								dense
								hide-details="auto"
								placeholder="Empresa portal"
								clearable
								:items="Object.entries($store.getters.getColoresEmpresas).map(([key, value]) => ({ nombre: key, color: value }))"
								v-model="colaborador.portal"
								item-value="nombre"
								item-text="nombre"
								:rules="[rules.req]"
							>
								<template v-slot:selection="{ item, selected, attrs, on }">
									<empresa-chip small :value="item.nombre"></empresa-chip>
								</template>

								<template v-slot:item="{ item }">
									<empresa-chip small :value="item.nombre"></empresa-chip>
								</template>
							</v-autocomplete>
						</aux-input>
						<v-divider />
						<aux-input title="Es subagente de">
							<!-- <v-text-field filled dense hide-details="auto" placeholder="Teléfono Colaborador" /> -->
							<v-autocomplete
								filled
								dense
								hide-details="auto"
								placeholder="Es subagente de"
								clearable
								:items="colaboradoresFiltrados"
								v-model="colaborador.parent"
								item-value="IdAgente"
								item-text="NombreContrato"
								return-object
								@click:clear="() => colaborador.parent = null"
								@change="colaborador.nombreEmpresa = colaborador.parent.NombreContrato"
							>
								<template v-slot:selection="{ item, selected, attrs, on }">
									<h4 class="h4">{{ item.NombreContrato || item.Nombre }}</h4>
								</template>

								<template v-slot:item="{ item }">
									<v-list-item-content>
										<v-list-item-title>{{ item.NombreContrato }}</v-list-item-title>
										<v-list-item-subtitle>{{ item.Nombre }}</v-list-item-subtitle>
									</v-list-item-content>
								</template>
							</v-autocomplete>
						</aux-input>
						<v-alert
							class="ma-4 my-2"
							type="info"
							dense
							text
							v-if="colaborador.parent != null"
						>Los siguientes datos depeneden de su agente</v-alert>
						<v-divider />
						<aux-input title="CIF">
							<v-text-field
								filled
								dense
								hide-details="auto"
								:disabled="colaborador.parent != null"
								v-model="colaborador.cif"
								placeholder="CIF Colaborador"
								:rules="[colaborador.parent || !colaborador.facturable ? true : rules.req]"
							/>
						</aux-input>
						<v-divider />
						<aux-input title="Cod Postal">
							<v-text-field
								filled
								dense
								hide-details="auto"
								v-model="colaborador.cp"
								:disabled="colaborador.parent != null"
								placeholder="Cod Postal Colaborador"
								:rules="[colaborador.parent || !colaborador.facturable ? true : rules.req, rules.isNumber]"
							/>
						</aux-input>
						<v-divider />
						<aux-input title="Ciudad">
							<v-text-field
								filled
								dense
								hide-details="auto"
								v-model="colaborador.ciudad"
								:disabled="colaborador.parent != null"
								placeholder="Ciudad Colaborador"
								:rules="[colaborador.parent || !colaborador.facturable ? true : rules.req]"
							/>
						</aux-input>
						<v-divider />
						<aux-input title="Provincia">
							<v-text-field
								filled
								dense
								hide-details="auto"
								v-model="colaborador.provincia"
								:disabled="colaborador.parent != null"
								placeholder="Provincia Colaborador"
								:rules="[colaborador.parent || !colaborador.facturable ? true : rules.req]"
							/>
						</aux-input>
						<v-divider />
						<aux-input title="Dirección">
							<v-text-field
								filled
								dense
								hide-details="auto"
								v-model="colaborador.direccion"
								:disabled="colaborador.parent != null"
								placeholder="Dirección Colaborador"
								:rules="[colaborador.parent || !colaborador.facturable ? true : rules.req]"
							/>
						</aux-input>
						<v-divider />
						<aux-input title="IBAN">
							<v-text-field
								filled
								dense
								hide-details="auto"
								v-model="colaborador.IBAN"
								:disabled="colaborador.parent != null"
								placeholder="IBAN Colaborador"
								:rules="[rules.iban, colaborador.parent || !colaborador.facturable ? true : rules.req]"
							/>
						</aux-input>
						<v-divider />
						<aux-input title="Porcentaje IVA">
							<v-text-field
								filled
								dense
								hide-details="auto"
								v-model="colaborador.porcentajeIva"
								:disabled="colaborador.parent != null"
								placeholder="Dirección Colaborador"
								:rules="[rules.isNumber, colaborador.parent || !colaborador.facturable ? true : rules.req]"
							/>
						</aux-input>
						<v-divider />
						<aux-input title="Porcentaje IRPF">
							<v-text-field
								filled
								dense
								hide-details="auto"
								v-model="colaborador.porcentajeIrpf"
								:disabled="colaborador.parent != null"
								placeholder="Cuenta corriente Colaborador"
								:rules="[rules.isNumber, colaborador.parent || !colaborador.facturable ? true : rules.req]"
							/>
						</aux-input>
					</tarjeta-datos>
				</v-col>

				<v-col cols="12" md="6">
					<tarjeta-datos header titulo="Permisos" icon="shield-lock-outline">
						<aux-input title="Login Usuario">
							<v-text-field
								filled
								dense
								hide-details="auto"
								v-model="colaborador.login"
								placeholder="Login Usuario"
								:rules="[rules.req, rules.loginRepetido]"
							/>
						</aux-input>
						<v-divider />
						<aux-input title="Contraseña">
							<v-text-field
								filled
								dense
								hide-details="auto"
								v-model="colaborador.password"
								placeholder="Contraseña"
								:rules="[rules.req]"
							>
								<template v-slot:append-outer>
									<v-btn class="mt-n2" color="primary" @click.stop="generarPassword">
										<v-icon>mdi-key</v-icon>
									</v-btn>
								</template>
							</v-text-field>
						</aux-input>
						<v-divider />
						<v-list>
							<v-list-item-group v-model="colaborador.permisos" multiple>
								<v-list-item
									dense
									:value="Number(permiso.valor)"
									:key="permiso.valor"
									v-for="permiso in descPermisos"
								>
									<template v-slot:default="{ active }">
										<v-list-item-action>
											<v-checkbox :input-value="active"></v-checkbox>
										</v-list-item-action>

										<v-list-item-content>
											<v-list-item-title>{{ permiso.desc }}</v-list-item-title>
											<v-list-item-subtitle>{{ permiso.permiso }}</v-list-item-subtitle>
										</v-list-item-content>
									</template>
								</v-list-item>
							</v-list-item-group>
						</v-list>
					</tarjeta-datos>
				</v-col>
			</v-row>
		</v-form>

		<v-bottom-sheet :value="checksPanel" inset persistent>
			<v-card>
				<v-timeline dense>
					<v-slide-x-transition group>
						<v-timeline-item
							fill-dot
							color="transparent"
							class="align-center"
							v-for="(value, key) in checks"
							:key="key"
						>
							<template v-slot:icon>
								<v-progress-circular
									v-if="
										!isNaN(value.max) &&
										(value.progress == null || value.progress == undefined)
									"
									:value="(value.actual * 100) / value.max"
									color="secondary"
								></v-progress-circular>
								<v-icon v-else-if="value.progress === false">mdi-minus</v-icon>
								<v-icon color="success" v-else-if="value.progress === true">mdi-check</v-icon>
								<v-icon color="error" v-else-if="value.progress === 'error'">mdi-close-octagon-outline</v-icon>
								<v-progress-circular
									v-else-if="value.progress === 'loading'"
									indeterminate
									color="secondary"
								></v-progress-circular>
							</template>
							<h4
								:class="{
									'error--text': value.progress == 'error',
									'success--text': value.progress === true,
									'grey--text': value.progress === false,
								}"
							>{{ value.texto }}</h4>
						</v-timeline-item>
					</v-slide-x-transition>
				</v-timeline>
			</v-card>
		</v-bottom-sheet>
	</v-container>
</template>

<script>
import { req, ValidateSpanishID, phone, email, iban, isNumber } from '@/utils/validations.js';

export default {
	components: {
		TarjetaDatos: () => import('@/components/TarjetaDatos.vue'),
		AuxInput: () => import('@/components/AuxInput.vue'),
		EmpresaChip: () => import('@/components/EmpresaChip.vue'),
		StatusChip: () => import('@/components/StatusChip.vue'),
	},
	data() {
		return {
			errors: null,
			descPermisos: [],
			// colaborador: { "permisos": [], "nombreEmpresa": "nadie", "nombreAgente": "don nadie", "correo": "nadie@nadie.es", "telefono": "644847584", "portal": { "nombre": "alumbraenergia", "color": "#E41270" }, "cif": "1234", "direccion": "1234", "IBAN": "ES1300815239413959455397", "porcentajeIva": "21", "porcentajeIrpf": "15", "login": "1234", "password": "MTBGOlBPliEIk3Ix" },
			colaborador: { permisos: [], facturable: true },
			colaboradores: [],
			loading: false,
			modelosFacturas: [],

			rules: {
				req, dni: ValidateSpanishID, phone, email, iban, isNumber,
				canalRepetido: (v) => !this.colaboradores.find(x => x.NombreContrato == v && x.ancestor_id == null) || 'El nombre del canal está repetido',
				agenteRepetido: (v) => !this.colaboradores.find(x => x.Nombre == v) || 'El nombre del agente está repetido',
				loginRepetido: (v) => !this.colaboradores.find(x => x.Login == v) || 'El inicio de sesión está repetido',
			},

			checks: {},
			checksPanel: false,
		}
	},
	computed: {
		totalPermisos() {
			return this.colaborador.permisos.reduce((t, a) => t + a, 0)
		},
		colaboradoresFiltrados() {
			return this.colaboradores.filter((v, i, a) => a.findIndex(t => (t.IdAgente === v.IdAgente)) === i && v.ancestor_id == null && v.IdAgente != null && v.idColaborador != this.colaborador.idColaborador)
		}
	},
	methods: {
		getModelosFactura() {
			return new Promise((resolve, reject) => {
				this.checks.cargandoModelosFactura.progress = 'loading';
				axios({
					method: "GET",
					url: `${process.env.VUE_APP_OUR_API_URL}/modelosFacturas.php`,
				}).then(({ data: modelos }) => {
					this.modelosFacturas = modelos
					this.checks.cargandoModelosFactura.progress = true;
					resolve()
				}).catch(err => {
					console.error(err)
					this.checks.cargandoModelosFactura.progress = 'error';
					reject();
				})
			})
		},
		getDescPermisos() {
			return new Promise((resolve, reject) => {
				this.checks.cargandoPermisos.progress = 'loading';
				axios({
					method: "GET",
					url: `${process.env.VUE_APP_OUR_API_URL}/permisos.php`,
				}).then(({ data: permisos }) => {
					permisos.filter(p => this.$root.acceso(p.permiso)).forEach(({ permiso, valor, desc }) => this.descPermisos.push({ permiso, valor, desc }))
					this.descPermisos = this.descPermisos.sort((a, b) => Number(a.valor) > Number(b.valor) ? 1 : -1)
					this.checks.cargandoPermisos.progress = true;
					resolve()
				}).catch(err => {
					console.error(err)
					this.checks.cargandoPermisos.progress = 'error';
					reject();
				})
			})
		},
		getColaboradores() {
			return new Promise((resolve, reject) => {
				this.checks.cargandoColaboradores.progress = 'loading';
				axios({
					method: "GET",
					url: `${process.env.VUE_APP_OUR_API_URL}/colaboradores.php`,
					params: {
						token: this.$store.getters.getJwtEmpresa,
					},
				}).then((res) => {
					this.colaboradores =
						res.data.filter(x => x.idColaborador != this.$route.query.idColaborador && x.ancestor_id !== (this.$route.query.idColaborador || ''))
					this.checks.cargandoColaboradores.progress = true;
					resolve();
				}).catch(err => {
					console.error(err)
					this.checks.cargandoColaboradores.progress = 'error';
				})
			})
		}, generarPassword() {
			const Password = {
				_pattern: /[a-zA-Z0-9_\-\+]/,

				_getRandomByte: function () {
					// http://caniuse.com/#feat=getrandomvalues
					if (window.crypto && window.crypto.getRandomValues) {
						var result = new Uint8Array(1);
						window.crypto.getRandomValues(result);
						return result[0];
					}
					else if (window.msCrypto && window.msCrypto.getRandomValues) {
						var result = new Uint8Array(1);
						window.msCrypto.getRandomValues(result);
						return result[0];
					}
					else {
						return Math.floor(Math.random() * 256);
					}
				},

				generate: function (length) {
					return Array.apply(null, { 'length': length })
						.map(function () {
							var result;
							while (true) {
								result = String.fromCharCode(this._getRandomByte());
								if (this._pattern.test(result)) {
									return result;
								}
							}
						}, this)
						.join('');
				}


			};
			this.$set(this.colaborador, 'password', Password.generate(Math.round(Math.random() * (16 - 10 + 1) + 10)))
		},
		async getColaborador() {
			return new Promise((resolve, reject) => {
				this.checks.cargandoColaborador.progress = 'loading';
				axios({
					method: "GET",
					url: `${process.env.VUE_APP_OUR_API_URL}/colaboradores.php`,
					params: {
						token: this.$store.getters.getJwtEmpresa,
						idColaborador: this.$route.query.idColaborador
					},
				}).then(({ data }) => {
					const potencias2 = new Array(50).fill(0).map((v, k) => 2 ** k);
					this.colaborador.permisos = potencias2.map(p => p & data.permisos_b).filter(p => p)

					this.colaborador = {
						...data,
						...this.colaborador,
						nombreEmpresa: data.Canal,
						nombreAgente: data.Nombre,
						login: data.Login,
						password: data.Password,
						correo: data.Correo,
						portal: data.Empresa,
						entorno: data.entorno,
						telefono: data.telefono,
						porcentajeIva: this.$route.query.idColaborador ? data.porcentajeIva : null,
						porcentajeIrpf: this.$route.query.idColaborador ? data.porcentajeIrpf : null,
						cif: this.$route.query.idColaborador ? data.cif : null,
						direccion: this.$route.query.idColaborador ? data.direccion : null,
						IBAN: this.$route.query.idColaborador ? data.IBAN : null,
						parent: data.ancestor_id,
					}

					// this.colaborador = res.data.filter((v, i, a) => a.findIndex(t => (t.IdAgente === v.IdAgente)) === i && v.ancestor_id == null && v.IdAgente != null)
					this.checks.cargandoColaborador.progress = true;

					resolve();
				}).catch(err => {
					this.checks.cargandoColaborador.progress = 'error';
					console.error(err)
					reject()
				})
			});
		},
		async crearColaborador() {
			this.errors = false;
			if (!this.$refs.formulario.validate()) {
				this.errors = true;
				return;
			}
			let usuarios = {
				token: this.$store.getters.getJwtEmpresa,

				Permisos: 0,
				Nombre: this.colaborador.nombreAgente,
				NombreEmpresa: this.colaborador.parent == null ? this.colaborador.nombreEmpresa : this.colaborador.nombreAgente,
				Login: this.colaborador.login,
				Password: this.colaborador.password,
				Correo: this.colaborador.correo,
				Empresa: this.colaborador.portal,
				permisos_b: this.totalPermisos,
				porcentajeIva: this.colaborador.porcentajeIva,
				porcentajeIrpf: this.colaborador.porcentajeIrpf,
				telefono: this.colaborador.telefono,
				cif: this.colaborador.cif,
				direccion: this.colaborador.direccion,
				IBAN: this.colaborador.IBAN,
				cp: this.colaborador.cp,
				ciudad: this.colaborador.ciudad,
				provincia: this.colaborador.provincia,

				IdModeloFactura: this.IdModeloFactura,

				CodigoTipoAgente: this.colaborador.parent && this.colaborador.parent.IdAgente ? 2 : 1,
				IdAgenteNivelAnterior: this.colaborador.parent ? this.colaborador.parent.IdAgente : null,

				idColaborador: this.$route.query.idColaborador || null,
				idAgente: this.colaborador.IdAgente,
			}

			axios({
				url: `${process.env.VUE_APP_OUR_API_URL}/colaboradores.php`,
				method: 'POST',
				data: usuarios,
			}).then(res => {
				this.$router.push({ name: 'Colaboradores' })
			}).catch(err => {
				console.error(err);
				this.$root.$emit('snack', 'ha ocurrido un error inesperado')
			})
		}
	},
	async mounted() {
		if (this.$route.query.idColaborador)
			this.$set(this.checks, "cargandoColaborador", {
				progress: false,
				texto: "Cargando datos del colaborador",
			});
		this.$set(this.checks, "cargandoColaboradores", {
			progress: false,
			texto: "Cargando lista de colaboradores",
		});
		this.$set(this.checks, "cargandoModelosFactura", {
			progress: false,
			texto: "Cargando modelos de facturas disponibles",
		});
		this.$set(this.checks, "cargandoPermisos", {
			progress: false,
			texto: "Cargando lista de permisos",
		});
		this.loading = true;
		await Promise.all([
			(this.$route.query.idColaborador) ? this.getColaborador() : null,
			this.getModelosFactura(),
			this.getDescPermisos(),
			this.getColaboradores(),
		])
		this.loading = false;
	},
	watch: {
		loading(val) {
			if (val) this.checksPanel = true;
			else {
				setTimeout(() => {
					this.checksPanel = false;
					this.checks = {};
				}, 1000);
			}
		}
	}
}
</script>